import React from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import { formatHref } from "utils/helpers"
import CTAArrow from "assets/svg/cta-arrow"

import classNames from "classnames"

import BackgroundImage from "./BackgroundImage"
import Evergreen from "./Evergreen"
import Header from "./Header"

import styles from "./Hero.module.scss"

const OverviewHero = ({
  backgroundImage,
  mobileBackgroundImage,
  caption,
  calloutImage,
  header,
  subHeader,
  topic,
  cta,
}) => {
  let width = typeof window === "object" ? window.innerWidth : null;
  let isMobile = width < 728 ? true : false;
  return (
    <Container fluid className={classNames([styles.hero, styles.shadow])}>
      <Evergreen caption={caption} calloutImage={calloutImage} isOverview={true} />
      <Container>
        <div className={styles.headerContainer}>
          <h4>Help prepare them for <br />accidental peanut exposure</h4>
          <h3><span>Wherever</span></h3>
          <h3>their day {isMobile && <br />}takes them</h3>
          
        </div>
      </Container>
      <BackgroundImage
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        isOverview={true}
      />
    </Container>
  )
}

export default OverviewHero
