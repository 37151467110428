import React from "react"

import { graphql, Link } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useHomeData } from "hooks/contentful/pages/useHomeData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import OverviewHero from "components/Hero/OverviewHero"
import { Container } from "react-bootstrap"
import Image from "components/Image"
import ExternalLink from "components/Modal/ExternalLink"

import CTAArrow from "assets/svg/cta-arrow"

import heroImage from "../images/masthead_palforzia_overview.jpg"
import heroMobileImage from "../images/masthead_mobile_palforzia_overview.jpg"
import exposuresImage from "../images/percent-of-exposures.png"
import exposuresMobileImage from "../images/percent-of-exposures-mobile.png"
import emergencyIcon from "../images/emergency-visit-icon.svg"
import pillIcon from "../images/pill-icon.png"
import patientsIcon from "../images/patients-icon.svg"
import caregiverIcon from "../images/caregiver-icon.svg"
import classroomImage from "../images/classroom-image.png"
import classroomImageMobile from "../images/classroom-image-mobile.png"

import blueOval from "../images/oval-blue.svg"
import greenOval from "../images/oval-green.svg"
import purpleOval from "../images/oval-purple.svg"

import classNames from "classnames"

import "../styles/main.scss"
import styles from "../styles/pages/palforziaoverview.module.scss"

const PalforziaOverview = ({ data }) => {
  const { hero, cards, referencesHeader } = useHomeData(data.page);
  let width = typeof window === "object" ? window.innerWidth : null;
  let isMobile = width >= 728 ? false : true;
  let newHero = {
    backgroundImage: {
      publicURL: heroImage
    },
    caption: 'For children aged 4 through 17 years',
    cta: '',
    header: 'Wherever their day takes them',
    mobileBackgroundImage: {
      publicURL: heroMobileImage 
    },
    isOverview: true
  }

  const references = ['Versluis A, Knulst AC, Kruizinga AG, et al. <span>Clin Exp Allergy</span>. 2015;45(2):347-367.', 'Gupta RS, Warren CM, Smith BM, et al. <span>Pediatrics</span>. 2018;142(6):e20181235.', 'PALFORZIA [package insert]. Brisbane, CA: Aimmune Therapeutics, Inc.'];
  const title = 'Overview | PALFORZIA [Peanut (Arachis hypogaea) Allergen Powder-dnfp]';
  const description = 'Learn more about PALFORZIA oral immunotherapy treatment, key information about the brand, and educational content.';
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={referencesHeader} references={references}>
        <SEO title={title} description={description} />
        <main className={styles.content}>
          <OverviewHero {...newHero} isOverview={true} />
          <Container className={styles.container} fluid>
            <Container className={styles.innerContainer}>
            <img src={blueOval} className={styles.oval1} role="presentation" />
            <img src={greenOval} className={styles.oval3} role="presentation" />
            <img src={greenOval} className={styles.oval2} role="presentation" />
            <img src={purpleOval} className={styles.oval4} role="presentation" />
            <img src={blueOval} className={styles.oval5} role="presentation" />
            <img src={purpleOval} className={styles.oval5purple} role="presentation" />
            <div className={styles.caption}>
              <h2 className={styles.header}>Complete avoidance <br />of peanut can be difficult</h2>
              <p className={styles.topic}>A systematic literature review found that children with food allergy experience accidental exposures to their specific food allergens in places where they commonly spend time, including their home, a friend’s house, school, and restaurants.<sup>1</sup></p>
              <div className={styles.graphicContainer}>
                <img src={exposuresImage} className={styles.graphicDesktop} alt={"Percentage of Exposures"} />
                <img src={exposuresMobileImage} className={styles.graphicMobile} alt={"Percentage of Exposures"} />
              </div>
            </div>
            <div className={styles.subCopy}>
                <div>
                  <img src={emergencyIcon} className={styles.icon} alt={"Emergency Visit Icon"} />
                  <p>Approximately <span>1 in 5</span> children with physician-diagnosed peanut allergy had at least <span>1 peanut allergy-related ED visit</span> in a single year.<sup>2</sup><sup className={styles.asterik}>*</sup></p>
                </div>
                <p>ED = emergency department.</p>
                <p><span>*</span>According to a population-based survey of US households with parent-proxy responses for 38,408 children.</p>
            </div>

            </Container>
          </Container>
          <Container className={styles.container} fluid>
            <Container className={classNames([styles.innerContainer, styles.worksSection])}>
              <img src={greenOval} className={styles.oval1} role="presentation" />
              <img src={blueOval} className={styles.oval2} role="presentation" />
              <img src={purpleOval} className={styles.oval3} role="presentation" />
              <div className={styles.caption}>
                <img src={pillIcon} className={styles.sideIcon} alt={"Icon of Pill"} />
                <div>
                  <h2 className={styles.header}>Palforzia works <br />with the immune system</h2>
                  <p>PALFORZIA can help gradually decrease a patient's sensitivity to peanuts over time through a process called oral immunotherapy (OIT). During OIT, a patient is slowly exposed to increasing amounts of peanut protein until the Maintenance Dose is reached. Although PALFORZIA is not a cure for peanut allergy, it can help lessen the severity of a reaction from accidental exposure.<sup>3</sup></p>
                  <p>PALFORZIA is not a treatment for allergic reactions and should not be given during an allergic reaction.<sup>3</sup></p>
                </div>
              </div>
            </Container>
          </Container>
          <Container className={styles.container} fluid>
            <Container className={styles.innerContainer}>
              {isMobile && 
                <h2 className={styles.header}>Palforzia was clinically proven</h2>
              }
              <div className={styles.caption}>
                <div>
                {!isMobile && 
                <h2 className={styles.header}>Palforzia was clinically proven</h2>
              }
                  <p>PALFORZIA is indicated for the mitigation of allergic reactions, including anaphylaxis, that may occur with accidental exposure to peanut.<sup>3</sup> </p>
                  <p>See the results from key studies <Link to={'/palisade'}>here</Link><span>.</span></p>
                </div>
                <img src={blueOval} className={styles.oval6} role="presentation" />
                <img src={purpleOval} className={styles.oval8mobile} role="presentation" />
                <img src={greenOval} className={styles.oval7} role="presentation" />
                <img src={classroomImage} className={classNames([styles.classroomImage, styles.classDesktop])} alt={"Classroom Image"} />
                <img src={classroomImageMobile} className={classNames([styles.classroomImage, styles.classMobile])} alt={"Classroom Image"} />
              </div>
            </Container>
          </Container>
          <Container className={styles.container} fluid>
            <Container className={styles.innerContainer}>
            <img src={greenOval} className={styles.oval9mobile} role="presentation" />
            <img src={purpleOval} className={styles.oval10} role="presentation" />
            <div className={styles.caption}>
              <img src={caregiverIcon} className={styles.sideIcon} alt={"Caregiver Icon"} />
              <div>
                <h2 className={styles.header}>Palforzia could be an option for your patients with peanut allergy</h2>
                <p>Ask your patients with peanut allergy and their caregivers whether they would like to learn more about a treatment option for their allergy, in addition to following a strict peanut-avoidant diet and carrying injectable epinephrine.</p>
              </div>
            </div>
            </Container>
          </Container>
          <Container className={styles.container} fluid>
            <img src={blueOval} className={styles.oval8} role="presentation" />
            <img src={purpleOval} className={styles.oval9} role="presentation" />
            <img src={blueOval} className={styles.oval10mobile} role="presentation" />
            <Container className={styles.innerContainer}>
            <div className={styles.ctaModules}>
              <div>
                <p>Explore the PALFORZIA<br /> clinical trial data, safety profile, <br />treatment pathway, and more</p>
                  <Link className={styles.cta} target={"self"} to={"/palisade"}>
                    Learn more now
                    <CTAArrow classes={styles.icon} />
                  </Link>
              </div>
              <span className={styles.border}></span>
              <div>
                <p>Learn how to become certified in the PALFORZIA REMS program or find a REMS-certified physician nearby</p>
                  <ExternalLink
                  url={'https://palforziarems.com/'}
                  className={styles.cta}
                >
                  GET STARTED WITH REMS
                  <CTAArrow classes={styles.icon} />
                  
                </ExternalLink>
              </div>
            </div>
            </Container>
          </Container>
          
        </main>
      </Layout>
    </StateProvider>
  )
}

export default PalforziaOverview

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Home" }) {
      label
      url
      title
      description
      content {
        ...HeroFragment
        ...HomeCardFragment
      }
      referencesHeader {
        ...ReferencedTitleFragment
      }
    }
  }
`
